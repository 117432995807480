import cart from '../utils/cart.jpg';
import distribution from '../utils/distribution.jpg';
import manufacture from '../utils/manufacturing.jpg';
import { HashLink as Link } from 'react-router-hash-link';
import Rdm from './Rdm';
import { useState } from 'react';

const SecondaryContainer = () => {
    const [t,setT] = useState("r");
    return (
        <div className="mt-5 text-center bg-opacity-35 from-gray-200">
            <h1 className="text-[#3AA9E6] font-semibold pt-[20%] text-4xl">Inventory & Accounting Software</h1>
            <div className="flex justify-around mt-8">
                <Link to='#retail' smooth>
                    <div className='mx-2 w-[120px]'>
                        <div className='bg-white rounded-full border border-black hover:transform hover:translate-y-[-5px] transition duration-300'>
                            <img className='rounded-full p-5' src={cart} />
                        </div>
                        <h4 className='font-semibold text-2xl text-[#2E3B55] mt-3'><i>Retail</i></h4>
                    </div>
                </Link>
                <Link to='#retail' smooth>
                    <div className='mx-2 w-[120px]'>
                        <div className='bg-white rounded-full border border-black hover:transform hover:translate-y-[-5px] transition duration-300'>
                            <img className='rounded-full p-5' src={distribution} />
                        </div>
                        <h4 className='font-semibold text-2xl text-[#2E3B55] mt-3'><i>Distribution</i></h4>
                    </div>
                </Link>
                <Link to="#retail" smooth>
                    <div className='mx-2 w-[120px]'>
                        <div className='bg-white rounded-full border border-black hover:transform hover:translate-y-[-5px] transition duration-300'>
                            <img className='rounded-full p-5' src={manufacture} />
                        </div>
                        <h4 className='font-semibold text-2xl text-[#2E3B55] mt-3'><i>Manufacturing</i></h4>
                    </div>
                </Link>
            </div>
            <div className='flex mt-16'>
                <button className='text-white bg-[#3AA9E6] text-lg font-bold px-5 rounded-lg ml-[13%] mx-5 p-3'>Request For Free Demo</button>
                <button className='text-white bg-[#2E3B55] text-lg font-bold ml-[10%] rounded-lg px-7 p-3'>Download Now</button>
            </div>
        </div>
    );
};
export default SecondaryContainer;
