import Footer from "./Footer";
import Header from "./Header";
import MainContainer from "./MainContainer";
import Pricing from "./Pricing";
import Rdm from "./Rdm";
import Services from "./Services";

const Body = () => {
    return (
        <div>
            <div>
                <Header />
            </div>
            <div>
                <MainContainer />
            </div>
            <div>
                <Rdm />
            </div>
            <div>
                <Services />
            </div>
            <div>
                <Pricing />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};
export default Body;