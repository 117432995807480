import retail from '../utils/retail_icon.png';
import distribution from '../utils/distribution_icon.png';
import manufacture from '../utils/manufacturing_icon.png';
import { useEffect, useState } from 'react';
import Retail from './Retail';
import Distribution from "./Distribution";
import Manufacturing from "./Manufacturing";

const Rdm = ({t}) => {
    const [type,setType] = useState("r");

    useEffect(() => {
        if(t == "distribution"){
            setType("d");
        }
        if(t == "manufacturing"){
            setType("m");
        }
    }, []);

    return (
        <div>
            <div className="text-center font-semibold mt-11">
                <h1 className="text-3xl font-serif text-[#3AA9E6]"><span className="text-[#2E3B55]">Drive your </span>business <span className="text-[#2E3B55]">with </span>Raa Infotech</h1>
            </div>
            <div className="flex justify-center mt-8"> 
                <div className='mx-8 w-[120px] hover:cursor-pointer'>
                    <div className='bg-white hover:transform hover:translate-y-[-5px] transition duration-300'>
                        <img className='p-2' onClick={() => {setType("r")}} src={retail} />
                    </div>
                    <h4 className='font-semibold text-xl text-center text-[#2E3B55] mt-2'><i>Retail</i></h4>
                    <h3 className='text-center font-sans'>Softwares</h3>
                </div>
                <div className='mx-8 w-[120px] hover:cursor-pointer'>
                    <div className='bg-white hover:transform hover:translate-y-[-5px] transition duration-300'>
                        <img className='p-2 h-24' onClick={() => {setType("d")}} src={distribution} />
                    </div>
                    <h4 className='font-semibold text-xl text-center text-[#2E3B55] mt-3'><i>Distribution</i></h4>
                    <h3 className='text-center font-sans'>Softwares</h3>
                </div>
                <div className='mx-8 w-[120px] hover:cursor-pointer'>
                    <div className='bg-white hover:transform hover:translate-y-[-5px] transition duration-300'>
                        <img className='p-5' onClick={() => {setType("m")}} src={manufacture} />
                    </div>
                    <h4 className='font-semibold text-xl text-center text-[#2E3B55] -mt-2'><i>Manufacturing</i></h4>
                    <h3 className='text-center font-sans'>Softwares</h3>
                </div>
            </div>
            {type === "r" && <Retail />}
            {type === "d" && <Distribution />}
            {type === "m" && <Manufacturing />}
        </div>
    );
};
export default Rdm;