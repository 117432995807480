import barcode from "../utils/services_icons/barcode.png";
import credit_card from "../utils/services_icons/credit card.png";
import direct_call from "../utils/services_icons/direct_call.png";
import e_retail from "../utils/services_icons/e-retail.png";
import gst from "../utils/services_icons/gst biling.png";
import inventory from "../utils/services_icons/inventory.png";
import invoice from "../utils/services_icons/invoice.png";
import mart from "../utils/services_icons/mart.png";
import payment from "../utils/services_icons/payment.png";
import purchase from "../utils/services_icons/purchase import.png";
import purchase_sale from "../utils/services_icons/purchase_sale.png";
import whatsapp from "../utils/services_icons/whatsapp_invoice.png";
import shop from "../utils/services_icons/shop qr.png";

const Services = () => {
    return (
        <div className="mt-20 bg-gray-50">
            <div className="text-center text-3xl pt-5 font-semibold">
                <h1 className="text-[#2E3B55]">Find better <span className="text-[#3AA9E6]">Solutions</span> Build better <span className="text-[#3AA9E6]">Products</span></h1>
            </div>
            <div className="grid grid-cols-12 mt-14">
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={barcode} />
                    <h6 className="font-medium mt-2">Barcode Management</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Helps encode & centralize all products information in a barcode to quickly & accurately track products during billing</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={credit_card} />
                    <h6 className="font-medium mt-2">Live Credit Limit Management</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Set & Track the credit limit for customers to save huge losses. Get live notification during billing whenever limit is reached</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={direct_call} />
                    <h6 className="font-medium mt-2">Direct Calling</h6>
                    <p className="w-2/3 text-center text-sm mt-2">To simplify the order taking process, connect your mobile with system by scanning QR code & place calls directly to customer for receiving orders</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={e_retail} />
                    <h6 className="font-medium mt-2">eRetail Web Application</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Directly place online orders to distributors & check status of all orders, View nearby distributors, schemes inside Raa Infotech</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={gst} />
                    <h6 className="font-medium mt-2">GST Billing & Return Filing</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Create GST invoices, multiple e-way bills & directly upload files in Excel, JSON or CSV format in GST portal and file GST returns</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={inventory} />
                    <h6 className="font-medium mt-2">Inventory Management</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Manage Focused, Dump and Near-Expiry stock level, set reorder points to replenish stock with Push Sale features</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={invoice} />
                    <h6 className="font-medium mt-2">e-Invoicing</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Get 15 paisa per auto e-Invoicing and easily generate error-free e-Invoices without going to the portal with zero downtime using Raa Infotech ERP</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={shop} />
                    <h6 className="font-medium mt-2">My Shop QR Code</h6>
                    <p className="w-2/3 text-center text-sm mt-2">List & upload products, schemes, offers in QR code. Print & paste outside shop/ counter where customers can directly scan & place orders</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={mart} />
                    <h6 className="font-medium mt-2">Mart</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Get your E-commerce website ready in just 15 minutes with no technical knowledge required. Enjoy easy Ordering & Inventory Management for Retailers and Distributors through Raa Infotech ERP. Save your time & effort.</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={payment} />
                    <h6 className="font-medium mt-2">Payment & Reconciliation</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Simplify your payments & bill-by-bill reconciliation using Raa Infotech Pay at 0% service charges & 2% cashback for retailers</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={purchase} />
                    <h6 className="font-medium mt-2">Online Purchase Import</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Import purchases can be made directly in the software through a PDF, Excel, or CSV file, eliminating the need to manually feed the purchase and ensuring 100% accuracy.</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={purchase_sale} />
                    <h6 className="font-medium mt-2">Purchase & Sale Claim</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Get timely reminders & keep a track of benefits of claim against the purchases which is being done with Claims & Statements feature</p>
                </div>
                <div className="flex flex-col items-center col-span-3 mb-16">
                    <img className="h-20 w-20" src={whatsapp} />
                    <h6 className="font-medium mt-2">Send Invoices on WhatsApp</h6>
                    <p className="w-2/3 text-center text-sm mt-2">Send invoices directly to your customers on WhatsApp. Boost and streamline your business operations with Raa Infotech ERP. Reduce paper usage & printing costs.</p>
                </div>
            </div>
        </div>
    );
};
export default Services;