import { SECONDARY_URL } from "../utils/urls";
import PrimaryContainer from "./PrimaryContainer";
import SecondaryContainer from "./SecondaryContainer";
import bg from "../utils/bg-img.jpeg"

const MainContainer = () => {
    return (
        <div className="relative pt-5">
            <img src={bg} alt="background" className="w-full h-[540px] object-cover" />
            <div className="absolute inset-0 grid grid-cols-12">
                <div className="col-span-5">
                    <PrimaryContainer />
                </div>
                <div className="col-span-7">
                    <SecondaryContainer />
                </div>
            </div>
        </div>
    );
};
export default MainContainer;
