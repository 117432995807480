import pharma from "../utils/distribution/pharma.png";
import automobile from "../utils/distribution/automobile.png";
import elec from "../utils/distribution/elec_app.png";
import fmcg from "../utils/distribution/fmcg.png";
import jewelry from "../utils/distribution/jewelry.png";
import mandi from "../utils/distribution/mandi.png";
import textile from "../utils/distribution/textile.png";
import trade from "../utils/distribution/trade.png";
import warehouse from "../utils/distribution/warehouse.png";
import side from "../utils/si.jpg";

const Distribution = () => {
    return (
        <div id="distribution" className="flex m-14 border border-gray-400 py-4 px-3">
            <div className="grid grid-cols-3 gap-x-16 gap-y-8">
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={pharma} />
                    <h5 className="mt-2 text-center">Pharma & Healthcare</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={automobile} />
                    <h5 className="mt-2 text-center">Automobile</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={elec} />
                    <h5 className="mt-2 text-center">Electronic Appliances</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={fmcg} />
                    <h5 className="mt-2 text-center">FMCG</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={jewelry} />
                    <h5 className="mt-2 text-center">Jewellery</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={mandi} />
                    <h5 className="mt-2 text-center">Mandi</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={textile} />
                    <h5 className="mt-2 text-center">Textile & Apparel</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={trade} />
                    <h5 className="mt-2 text-center">Import/Export Trading</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={warehouse} />
                    <h5 className="mt-2 text-center">Warehouse</h5>
                </div>
            </div>
            <div className="ml-16 mt-2">
                <img className="w-[550px] h-[400px] " src={side} />
            </div>
        </div>
    );
};
export default Distribution;