import insta from "../utils/instagram.png";
import phone from "../utils/phone.png"

const Footer = () => {
    return (
        <div id="footer" className="grid grid-cols-12 bg-[#2E3B55] text-white">
            <div className="mt-3 mb-5 ml-8 col-span-4">
                <h2 className="text-2xl font-bold mb-2">Our Company</h2>
                <h4>About Us</h4>
                <h4>Products & Services</h4>
                <h4>SiteMap</h4>
                <h4>Download Brochure</h4>
            </div>
            <div className="mt-3 mb-5 ml-10 col-span-4">
                <h2 className="text-2xl font-bold mb-2">Reach Us</h2>
                <h4 className="font-medium text-lg">Raa Infotech</h4>
                <h2 className="w-3/4 font-thin">70 C/3, Gali No. 2, I Main Market, Near SBI ATM, East of Kailash, New Delhi-110065, Delhi, India</h2>
            </div>
            <div className="mt-3 mb-5 ml-9 col-span-4">
                <h2 className="text-2xl font-bold mb-2">Connect With Us</h2>
                <div className="flex mb-2 mt-3">
                    <img className="h-6 w-6 mr-2" src={phone} />
                    <p>+91 8447175233</p>
                </div>
                <div className="flex">
                    <img className="h-6 w-6 mt-2" src={insta} />
                    <a href="https://www.instagram.com/raainfotech?igsh=ZGk2ZG10emNzMnV6" className="ml-2 mt-1 font-medium">Instagram</a>
                </div>
            </div>
        </div>
    );
};
export default Footer;
