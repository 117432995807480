import bookstore from "../utils/retail/bookstore.png";
import departmental from "../utils/retail/departmental.png";
import footwear from "../utils/retail/footwear.png";
import garment from "../utils/retail/garment.png";
import jewelry from "../utils/retail/jewelry.png";
import grocery from "../utils/retail/grocery.png";
import pharmacy from "../utils/retail/pharmacy.png";
import pos from "../utils/retail/pos.png";
import restaurant from "../utils/retail/restaurant.png";
import retail_shop from "../utils/retail/retail_shop.png";
import salon from "../utils/retail/salon.png";
import supermarket from "../utils/retail/supermarket.png";
import side from "../utils/si.jpg";

const Retail = () => {
    return (
        <div id="retail" className="flex m-14 border border-gray-400">
            <div className="grid grid-cols-4 mx-3 px-3 py-6 gap-x-16 gap-y-8">
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={bookstore} />
                    <h5 className="mt-2 text-center">Bookstore</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={departmental} />
                    <h5 className="mt-2 text-center">Departmental</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={footwear} />
                    <h5 className="mt-2 text-center">Footwear</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={garment} />
                    <h5 className="mt-2 text-center">Garment</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={jewelry} />
                    <h5 className="mt-2 text-center">Jewellery</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={grocery} />
                    <h5 className="mt-2 text-center">Grocery</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={pharmacy} />
                    <h5 className="mt-2 text-center">Pharmacy</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={pos} />
                    <h5 className="mt-2 text-center">POS</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={restaurant} />
                    <h5 className="mt-2 text-center">Restaurant</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={retail_shop} />
                    <h5 className="mt-2 text-center">Retail Shop</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={salon} />
                    <h5 className="mt-2 text-center">Salon & Spa</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={supermarket} />
                    <h5 className="mt-2 text-center">Supermarket</h5>
                </div>
            </div>
            <div className="ml-16 mt-2">
                <img className="w-[550px] h-[400px] " src={side} />
            </div>
        </div>
    );
};
export default Retail;