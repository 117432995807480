import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import Body from "./components/Body";
const App = () => {
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <Body />
    }
  ]);

  return (
    <div>
      <RouterProvider router={appRouter} />
    </div>
  )
};
export default App;