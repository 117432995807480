import CMMI_LOGO from "../utils/cmmi.jpg";
import GDPR_LOGO from "../utils/gdpr.jpg";
import ISO_LOGO from "../utils/iso.jpg";

const PrimaryContainer = () => {
    return (
        <div className="mt-5 bg-gray-100 bg-opacity-55 text-center">
            <div className="pt-[40%]">
                <h1 className="text-[#3AA9E6] text-5xl font-bold font-[sans-serif]"><strong className="text-[#2E3B55] font-serif">Raa Infotech </strong>ERP</h1>
                <h3 className="pt-4 text-2xl text-[#2E3B55]">GST Billing to Filing</h3>
                <h4 className="text-[#2E3B55] pt-1"><strong>850+</strong> Sales & support Centres / <strong>1 Million+</strong> Satisfied users</h4>
            </div>
            <div className="flex justify-center pt-6 pb-28">
                <img className="w-20 h-20 mr-3" src={CMMI_LOGO} />
                <img className="w-20 h-20 mr-3" src={ISO_LOGO} />
                <img className="w-20 h-20" src={GDPR_LOGO} />
            </div>
        </div>
    );
};
export default PrimaryContainer;