import assemble from "../utils/manufacturing/assemble.png";
import automobile from "../utils/manufacturing/automobile.png";
import ayurvedic from "../utils/manufacturing/ayurvedic.png";
import electronic from "../utils/manufacturing/electronic_manufacture.png";
import food from "../utils/manufacturing/food.png";
import pharmaceutical from "../utils/manufacturing/Pharmaceutical.png";
import process from "../utils/manufacturing/process_manufacturing.png";
import textile from "../utils/manufacturing/textile.png";
import side from "../utils/si.jpg";

const Manufacturing = () => {
    return (
        <div id="manufacturing" className="flex m-14 border border-gray-400 py-4 px-3">
            <div className="grid grid-cols-3 gap-x-16 gap-y-8">
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={assemble} />
                    <h5 className="mt-2 text-center">Assembling</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={automobile} />
                    <h5 className="mt-2 text-center">Automobile</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={ayurvedic} />
                    <h5 className="mt-2 text-center">Ayurvedic & Homeopathic</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={electronic} />
                    <h5 className="mt-2 text-center">Electronics Manufacturing</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={food} />
                    <h5 className="mt-2 text-center">Food & Beverage</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={pharmaceutical} />
                    <h5 className="mt-2 text-center">Pharmaceutical</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={textile} />
                    <h5 className="mt-2 text-center">Textile</h5>
                </div>
                <div className="flex flex-col items-center">
                    <img className="h-16 w-16" src={process} />
                    <h5 className="mt-2 text-center">Process Manufacturing</h5>
                </div>
            </div>
            <div className="ml-16 mt-2">
                <img className="w-[550px] h-[400px] " src={side} />
            </div>
        </div>
    );
};
export default Manufacturing;