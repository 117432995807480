import tick from "../utils/tick.png";

const Pricing = () => {
    return (
        <div>
            <div className="text-3xl font-semibold text-center mt-9 mb-8">
                <h1 className="text-[#3AA9E6]">Pricing <span className="text-[#2E3B55]">and </span>Subscription Models</h1>
            </div>
            <div className="flex justify-around mb-8 mt-16">
                <div className="p-3 shadow-2xl border border-spacing-3 border-gray-200 bg-gray-100 h-[55vh] w-1/4 transition-transform transform hover:-translate-y-2 hover:bg-gray-100 hover:border-gray-300 hover:shadow-xl">
                    <h2 className="text-center text-3xl font-bold text-[#2E3B55]">Basic Edition</h2>
                    <h6 className="text-center bg-red-200 mt-1 w-36 ml-16 rounded-2xl text-red-700 font-semibold">(Limited Edition)</h6>
                    <p className="text-sm text-center text-gray-600 mt-1">18% GST Extra</p>
                    <div className="flex mt-6">
                        <img className="h-4 w-4" src={tick} />
                        <h2 className="-mt-1 ml-3 font-medium">1 User Full Rights</h2>
                    </div>
                    <p className="ml-7 text-sm mt-1 font-sans">₹ 3000/- per extra user</p>
                    <p className="ml-7 text-sm mt-1">Maximum 2 Users</p>
                    <div className="bg-[#3AA9E6] mt-16 p-3 text-white font-bold hover:cursor-pointer text-xl rounded-lg text-center">
                        <button>₹ 8991/</button>
                    </div>
                </div>
                <div className="p-3 shadow-2xl border border-spacing-3 border-gray-200 bg-gray-100 h-[55vh] w-1/4 transition-transform transform hover:-translate-y-2 hover:bg-gray-100 hover:border-gray-300 hover:shadow-xl">
                    <h2 className="text-center text-3xl font-bold text-[#2E3B55]">Advanced Edition</h2>
                    <p className="text-sm text-center text-gray-600 mt-1">18% GST Extra</p>
                    <div className="flex mt-6">
                        <img className="h-4 w-4" src={tick} />
                        <h2 className="-mt-1 ml-3 font-medium">1 User Full Rights</h2>
                    </div>
                    <div className="flex mt-2">
                        <img className="h-4 w-4" src={tick} />
                        <h2 className="-mt-1 ml-3 font-medium">1 User for View Only</h2>
                    </div>
                    <p className="ml-7 text-sm mt-1 font-sans">₹ 3000/- per extra user</p>
                    <div className="bg-[#3AA9E6] mt-[87px] p-3 text-white font-bold hover:cursor-pointer text-xl rounded-lg text-center">
                        <button>₹ 12600/</button>
                    </div>
                </div>
                <div className="p-3 shadow-2xl border border-spacing-3 border-gray-200 bg-gray-100 h-[55vh] w-1/4 transition-transform transform hover:-translate-y-2 hover:bg-gray-100 hover:border-gray-300 hover:shadow-xl">
                    <h2 className="text-center text-3xl font-bold text-[#2E3B55]">Premium Edition</h2>
                    <p className="text-sm text-center text-gray-600 mt-1">18% GST Extra</p>
                    <div className="flex mt-6">
                        <img className="h-4 w-4" src={tick} />
                        <h2 className="-mt-1 ml-3 font-medium">Unlimited Users</h2>
                    </div>
                    <div className="bg-[#3AA9E6] mt-[136px] p-3 text-white font-bold hover:cursor-pointer text-xl rounded-lg text-center">
                        <button>₹ 25200/</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Pricing;