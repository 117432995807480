import { Link } from "react-router-dom";
import { LOGO_URL } from "../utils/urls";
import { HashLink } from "react-router-hash-link";

const Header = () => {
    return (
        <div className="flex justify-between">
            <div>
                <Link to='/'><img className="pt-4 ml-5 h-20 w-24 hover:cursor-pointer" src={LOGO_URL} alt="Logo" /></Link>
            </div>
            <HashLink to="#footer" smooth>
                <div className="m-5">
                    <button className="mx-4 hover:cursor-pointer font-bold bg-[#2E3B55] p-2 px-4 rounded-lg text-white">About</button>
                    <button className="mx-4 hover:cursor-pointer font-bold bg-[#3AA9E6] p-2 px-4 rounded-lg text-white">Contact</button>
                </div>
            </HashLink>
        </div>
    );
};
export default Header;